import {
  installRegisteredApps,
  MA_APP_IDS,
  maybeInstallMembersArea,
  withMembersArea,
  isMembersAreaInstalled,
} from '@wix/members-area-integration-kit'
import type {EditorPlatformApp, EditorSDK} from '@wix/platform-editor-sdk'
import type {EditorReadyFn} from '@wix/yoshi-flow-editor'
import {
  EVENTS_SECTION_ID,
  EVENTS_WIDGET_ID,
  EVENTS_DETAILS_PAGE_ID,
  MAIN_DC_URL,
  SCHEDULE_SECTION_ID,
  EVENTS_WEB_API_BASE,
  ExperimentNames,
  MEMBERS_AREA_V2_APP_ID,
  EVENTS_MEMBERS_WIDGET_ID,
} from '@wix/wix-events-commons-statics'
import {getAppManifestFactory} from './editor-script/app-manifest/app-manifest'
import {PROMO_INSTALL_CONFIG, PROMO_UPDATE_CONFIG} from './editor-script/constants'
import {onEventFactory} from './editor-script/events'
import {ensurePagesManaged} from './editor-script/pages-panel'
import {getTranslateFunction, TFunction} from './editor-script/services/translations'
import {parseStaticsUrlFromEditorScriptUrl} from './editor-script/services/url'
import {createWithApproval, WithApproval} from './editor-script/services/concurrent-editing'
import {FlowApi} from './editor-script/types'
import {ProgressBar, SdkProgressBar, SilentProgressBar} from './editor-script/services/progress-bar'
import {SdkWrapper} from './editor-script/services/sdk'
import {handleAppInstalled, handleMigrate, installPage} from './editor-script/editor-action-handlers'

let sdk: EditorSDK
let sdkWrapper: ReturnType<typeof SdkWrapper>
let appToken: string
let t: TFunction
let adi: boolean
let forcedPageInstall: boolean
let staticsUrl: string
let locale: string
let disableMembersInstall: boolean
let pageInstallFlowEnabled: boolean
let handleMembersAreaSilentInstallEnabled: boolean
let withApproval: WithApproval
let flowApi: FlowApi
let responsive: boolean
let progressBar: ProgressBar

const silentOrigins = new Set(['SITE_CREATION'])
const pageInstallOrigins = new Set(['SITE_CREATION', 'ADD_SECTION_PANEL', 'ADD_PAGE_PANEL'])

interface EventsAppInterface extends Omit<EditorPlatformApp, 'editorReady'> {
  editorReady: EditorReadyFn
}

const EventsApp: EventsAppInterface = {
  editorReady: async (editorSDK, token, options, _flowApi) => {
    flowApi = _flowApi
    sdk = editorSDK
    appToken = token
    sdkWrapper = SdkWrapper(sdk, appToken)

    locale = flowApi.environment.userLanguage
    const origin = options?.origin?.info?.type

    staticsUrl = parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl)
    t = await getTranslateFunction(staticsUrl, locale, flowApi.httpClient)
    const silent = silentOrigins.has(origin)
    forcedPageInstall = pageInstallOrigins.has(origin)
    disableMembersInstall = origin === 'SITE_CREATION'
    pageInstallFlowEnabled = flowApi.experiments.enabled(ExperimentNames.PageInstallFlow)
    handleMembersAreaSilentInstallEnabled = flowApi.experiments.enabled(ExperimentNames.HandleMembersAreaSilentInstall)
    adi = options?.origin?.type === 'ADI'
    responsive = options?.origin?.type === 'RESPONSIVE'
    withApproval = createWithApproval(sdk, token, adi, flowApi)
    progressBar = silent ? SilentProgressBar() : SdkProgressBar(sdk, t, token)

    await withApproval(async () => {
      await installPage({
        editorSDK: sdk,
        token,
        pageId: EVENTS_SECTION_ID,
        title: t('pagesPanelEventsDetailsTitle'),
        progressBar,
        forcedPageInstall,
      })

      await installPage({
        editorSDK: sdk,
        token,
        pageId: SCHEDULE_SECTION_ID,
        title: t('pagesPanelEventsScheduleTitle'),
        progressBar,
        forcedPageInstall,
      })

      await ensurePagesManaged(sdk, token, t)
    })

    onSitePublished()
    onWidgetAdded()

    await setAppAPI()
  },

  handleAction: async ({type, payload}) => {
    switch (type) {
      case 'appInstalled':
        return handleAppInstalled({
          appDefinitionId: (payload as any)?.appDefinitionId,
          pageInstallFlowEnabled,
          disableMembersInstall,
          adi,
          progressBar,
          withApproval,
          editorSdk: sdk,
          appToken,
          forcedPageInstall,
          handleMembersAreaSilentInstallEnabled,
        })
      case 'migrate':
        return handleMigrate({payload, withApproval, editorSdk: sdk, appToken, t, progressBar, forcedPageInstall})
      default:
        console.log(type, payload)
        return
    }
  },
  getAppManifest: getAppManifestFactory(
    () => t,
    () => locale,
    () => appToken,
    () => sdk,
    () => flowApi,
    () => responsive,
  ),
  onEvent: onEventFactory(
    () => appToken,
    () => flowApi,
  ),
}

const setAppAPI = async () => {
  const api: EventsApi = {
    subscribeToRevisionChanged: (): void => null,
    installMembersArea: async () => withApproval(installMembersArea),
    installMembersAreaSections: async () => withApproval(installMembersAreaSections),
    isMembersAreaInstalled,
    isEventsInMembersInstalled,
  }
  await sdk.editor.setAppAPI(appToken, api)
}

interface MembersEditorApi {
  getRoutes: () => {widgetId: string}[]
}
const getMembersAreaEditorApi = (): Promise<MembersEditorApi> =>
  sdk.document.application.getPublicAPI('', {
    appDefinitionId: MEMBERS_AREA_V2_APP_ID,
  }) as Promise<MembersEditorApi>

const isEventsInMembersInstalled = async () => {
  try {
    // Members said they will expose some better function for checking plugin status
    const membersEditorApi = await getMembersAreaEditorApi()
    const routes = await membersEditorApi.getRoutes()
    return Boolean(routes.find(route => route.widgetId === EVENTS_MEMBERS_WIDGET_ID))
  } catch (e) {
    // always throws on no api
  }
  return false
}

const installMembersArea = async () => {
  progressBar.open(PROMO_INSTALL_CONFIG)
  await maybeInstallMembersArea()
  await progressBar.close()
}

const installMembersAreaSections = async () => {
  progressBar.open(PROMO_UPDATE_CONFIG)
  await installRegisteredApps()
  await progressBar.close()
}
const widgetAddedHandler = (origCompId: string, compId: string) =>
  flowApi.httpClient.get(
    `${MAIN_DC_URL}${EVENTS_WEB_API_BASE}/web/provision-component?originCompId=${origCompId}&compId=${compId}`,
  )

const onWidgetAdded = () =>
  sdk.addEventListener('widgetAdded', async ({detail: {componentRef, originalComponentId}}) => {
    if (!originalComponentId) {
      return
    }

    return widgetAddedHandler(originalComponentId, componentRef.id)
  })

const onSitePublished = () => {
  sdk.addEventListener('siteWasPublished', async () => {
    const components = await sdkWrapper.getAppComponents()
    const compIds = components
      .filter(component =>
        [EVENTS_WIDGET_ID, adi ? EVENTS_DETAILS_PAGE_ID : null].filter(Boolean).includes(component.widgetId),
      )
      .map(widget => widget.id)

    const responses = await Promise.all(
      compIds.map(compId =>
        flowApi.httpClient.get<{component: wix.events.editor.WebComponent}>(
          `${MAIN_DC_URL}${EVENTS_WEB_API_BASE}/web/component/${compId}/draft`,
        ),
      ),
    )

    await Promise.all(
      responses.map(response =>
        flowApi.httpClient.put(`${MAIN_DC_URL}${EVENTS_WEB_API_BASE}/web/component/${response.data.component.id}`, {
          component: response.data.component.config,
        }),
      ),
    )
  })
}

const editorApp = withMembersArea(EventsApp, {
  installAutomatically: false,
  membersAreaApps: [MA_APP_IDS.ABOUT, MA_APP_IDS.MY_EVENTS, MA_APP_IDS.MY_WALLET],
})

export const editorReady = editorApp.editorReady
export const handleAction = editorApp.handleAction
export const onEvent = editorApp.onEvent
export const getAppManifest = editorApp.getAppManifest
